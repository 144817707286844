import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function OutlineButton(props) {
  const history = useNavigate();
  function gotourl() {
    history(props.url);
  }
  return (
    <button
      onClick={gotourl}
      style={{ color: "#00B4D8" }}
      className="rounded-lg  hover:transition ease-in-out delay-150 hover:text-black text-white bg-transparent text-blue-500 flex"
    >
      {props.name} <FaArrowRight style={{ margin: "5px" }} />
    </button>
  );
}

export default OutlineButton;
