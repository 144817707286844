import React from 'react'
import Step from "../BillsnPay/Step"

function Steps() {
  return (
    <div className="stepGrid">
        <Step title={"Step 1"} description={"Schedule a demo to get full understanding of the intricacies of the core banking application."} />
        <Step title={"Step 2"} description={"Provide relevant information about financial institution such as name, type and interested features."} />
        <Step title={"Step 3"} description={"Integration into the finedge core banking application."} />
    </div>
  )
}

export default Steps