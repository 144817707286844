import React, { useEffect } from "react";
import NavMenu from "../../components/NavMenu/NavMenu";
import Hero from "../../components/UCP/Hero";
import "./UCPHero.css";
import Features from "../../components/UCP/Features";
import HowItWorks from "../../components/UCP/HowItWorks";
import Info from "../../components/Info/Info";
import { Helmet } from "react-helmet";
import ShowModal from "../../components/RequestDemo/ShowModal";

function Smerp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Unified Corporative Platform (UCP) | Fifthlab </title>
        <meta
          name="description"
          content="Improve, Automate & Digitize Cooperative Operations"
        />
        <link rel="canonical" href="https://thefifthlab.com/example" />
      </Helmet>
      <NavMenu url={"products"} />
      <div className="aboutBody">
        <Hero />
        <Features />
        <ShowModal />
      </div>
      <HowItWorks />
      <Info />
    </div>
  );
}

export default Smerp;
