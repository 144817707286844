import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import NavMenu from "../../components/NavMenu/NavMenu";
import Brands from "../../components/Brands/Brands";
import Mission from "../../components/Mission/Mission";
import Products from "../../components/Products/Products";
import Updates from "../../components/Updates/Updates";
import Info from "../../components/Info/Info";
import { Helmet } from "react-helmet";
import fetchArticles from "../../api/api";
import ShowModal from "../../components/RequestDemo/ShowModal";

function Home() {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const response = await fetchArticles();
        setArticles(response);
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, [setArticles]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ecosystem of Solutions that work | Fifthlab </title>
        <meta name="description" content="A subsidiary of CWG plc" />
        <link rel="canonical" href="https://thefifthlab.com" />
      </Helmet>

      <NavMenu url="home" className="navStyle" />
      <div className="bannerContainer">
        <Banner />
        <ShowModal />
      </div>

      <Brands />

      <Mission />
      <Products />
      <Updates articles={articles} />

      <Info />
    </div>
  );
}

export default Home;
