import React from 'react'
import Steps from './Steps'

function HowItWorks() {
  return (
    <div className="howContainer" style={{background:"#EDFAFC"}}>
        <div className="aboutBody">
            <div className="howFlex">
            <div>
            <h2 className="howTitle">
                How It Works
            </h2>
            </div>

            <div>
            <Steps />
            </div>
            </div>
        </div>
    </div>
  )
}

export default HowItWorks