import React from 'react'
import Step from "../BillsnPay/Step"

function Steps() {
  return (
    <div className="stepGrid">
        <Step title={"Step 1"} description={"Download the Kuleanpay app from google play store or app store. You can also create a free account on our web app."} />
        <Step title={"Step 2"} description={"Create an account by providing the relevant information and automatically get a registered to start making transactions fearlessly."} />
        <Step title={"Step 3"} description={"Create or join a transaction on Kuleanpay and sleep with your two eyes closed, because what you pay for is what you get!"} />
    </div>
  )
}

export default Steps