import React from "react";
import BlogAvatar from "../../assets/images/logo.svg";

function ArticleAuthor({ article }) {
  return (
    <div className="flex my-2">
      <img
        className="w-14 h-14 rounded-full border p-2"
        src={BlogAvatar}
        alt="Rounded avatar"
      />
      <div className="ml-3 mt-1">
        <h3 className="article-author">Fifthlab Africa</h3>
        <span className="article-meta">
          {" "}
          3 min read · {article.pubDate.split(" ")[0]}
        </span>
      </div>
    </div>
  );
}

export default ArticleAuthor;
