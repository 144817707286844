import React from "react";
import Blog from "./Blog";
import "./Updates.css";
import { useNavigate } from "react-router";

function Updates({ articles }) {
  const history = useNavigate();
  const viewAllArticles = () => {
    history("/blog");
  };
  const showArticle = (article) => {
    sessionStorage.setItem("article", JSON.stringify(article));
    window.scrollTo(0, 0);
    const id = article.guid.split("/")[4];
    history("/blog/" + id);
  };

  return (
    <div id="insights" className="updateContainer">
      <div className="updateText">Updates & Insights</div>
      <div className="gridContainer">
        {articles &&
          articles.map((article, i) => (
            <Blog
              title={article.title}
              image={"https://cdn-images-1.medium.com/max/612/1*T8ZY8odPg7fU-gte8O6t-A.png"}
              categories={article.categories}
              time={article.pubDate.split(" ")[0]}
              author={article.author}
              key={i}
              url={article.link}
              onClick={() => showArticle(article)}
            />
          ))}
      </div>

      <div className="text-center">
        <button
          onClick={viewAllArticles}
          className="exploreButton buttonPadding border border-blue-500 rounded-full"
        >
          Explore More
        </button>
      </div>
    </div>
  );
}

export default Updates;
