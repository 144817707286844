import React from 'react'

function FeaturesCard(props) {
  return (
    <div className="featureCard">
        <span>{props.tag}</span>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
    </div>
  )
}

export default FeaturesCard