import React, { useEffect } from "react";
import NavMenu from "../../components/NavMenu/NavMenu";
import Hero from "../../components/Smerp/Hero";
import "./SmerpHero.css";
import Features from "../../components/Smerp/Features";
import HowItWorks from "../../components/Smerp/HowItWorks";
import Info from "../../components/Info/Info";
import { Helmet } from "react-helmet";
import ShowModal from "../../components/RequestDemo/ShowModal";

function Smerp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Transform your business process | Fifthlab </title>
        <meta name="description" content="Transform your business process" />
        <link rel="canonical" href="https://thefifthlab.com/products/smerp" />
      </Helmet>

      <NavMenu url={"products"} />
      <div className="aboutBody">
        <Hero />
        <Features />
        <ShowModal />
      </div>
      <HowItWorks />
      <Info />
    </div>
  );
}

export default Smerp;
