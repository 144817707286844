import React from 'react'
import OutlineButton from '../Buttons/OutlineButton'

function TabCard(props) {
  return (
    <div className="tabCardContainer mt-14 px-3">
        <div className="items-center overflow-hidden relative">
    <div className="bg-white text-gray-800 relative md:text-left">
        <div  className="md:flex items-center">
            
            <div>
                <div className="mb-10">
                    <h1 className="text-left font-bold my-5 text-2xl">{props.title}</h1>
                    <p className="text-md tabDescription">{props.description}</p>
                </div>
                <div className="text-left">
                    <div className="text-left mb-5 inline-block align-bottom">
                       <OutlineButton url={props.url} name={"Explore "+props.name}  />
                    </div>
                   
                </div>
            </div>

            <div className="mb-10">
                    <img src={props.image} className="tabImage rounded-lg relative z-10" alt="" />
              
            </div>
        </div>
    </div>
</div>





    </div>
  )
}

export default TabCard