import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import "./NavMenu.css";
import { FiChevronDown,FiChevronUp } from "react-icons/fi";
import NavList from "./NavList";

function NavMenu(props) {
  const history = useNavigate();
  const [logo] = useState(Logo);
  const [menuOpened, setMenuOpened] = useState(false);
  const [productMenuOpened,setProductMenu] = useState(false)
  function openNav() {
    setMenuOpened(true);
    document.getElementById("myNav").style.width = "100%";
  }

  function closeNav() {
    setMenuOpened(false);
    document.getElementById("myNav").style.width = "0%";
  }

  function showModal() {
    closeNav()
    document.getElementById("modalButton").click();
  }

  function showProducts(){
    let menuList = document.getElementById("productMenu")
    setMenuOpened(true);
    if(!productMenuOpened){
      menuList.style.display = "block"
      setProductMenu(true)
    } 
    else if(productMenuOpened){
      menuList.style.display = "none"
      setProductMenu(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (window.scrollY > 40) {
        document.getElementById("menu") &&
        document.getElementById("menu").classList.add("navShadow");
        return;
      } else if (window.scrollY < 39) {
        document.getElementById("menu") &&
        document.getElementById("menu").classList.remove("navShadow");
        return;
      }
    });

    window.addEventListener("scroll", function () {
      if (window.scrollY > 590) {
        document.getElementById("mobileMenu") &&
        document.getElementById("mobileMenu").classList.add("navShadow");
        return;
      } else {
        document.getElementById("mobileMenu") &&
        document.getElementById("mobileMenu").classList.remove("navShadow");
      }
    });
  }, []);


  function gotohome(){
    history('/')
    closeNav()
  }

  function gotoabout(){
    history('/about')
    closeNav()
  }

  function gotobillsnpay(){
    history("/products/billsnpay")
    closeNav()
    showProducts()
  }

  function gotosmerp(){
    history("/products/smerp")
    closeNav()
    showProducts()
  }

  function gotoucp(){
    history("/products/ucp")
    closeNav()
    showProducts()
  }

  function gotofinedge(){
    history("/products/finedge")
    closeNav()
    showProducts()
  }

  function gotokuleanpay(){
    history("/products/kuleanpay")
    closeNav()
    showProducts()
  }


  return (
    <div id="menu" className={'navbar '+props.className}>
      <div className="navContainer">
        {/* Laptop Menu */}
        <div className="ml-2 largescreen lg:flex justify-between">
          <div className="flex logoPosition">
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history("/")}
              src={logo}
              alt="fifthlablogo"
            />
          </div>

          <div className="py-5">
            <div className="menuItems flex justify-center">
              <li>
                <span
                  onClick={() => history("/")}
                  className={props.url === "home" ? "active" : ""}
                >
                  Home
                </span>
              </li>
              <li>
                <span
                  onClick={() => history("/about")}
                  className={props.url === "about" ? "active" : ""}
                >
                  About Us
                </span>
              </li>
              <li className="dropdown">
                <div className="dropdown">
                  <button
                    onClick={() => history("/products/billsnpay")}
                    className={
                      props.url === "products"
                        ? "active dropbtn flex"
                        : "dropbtn flex"
                    }
                  >
                    Products {productMenuOpened? <FiChevronUp style={{ margin: "5px" }} />:<FiChevronDown style={{ margin: "5px" }} />}
                  </button>
                  <div className="dropdown-developers">
                    <div className="justify-center flex z-20 relative">
                      <div>
                        <button
                          onClick={() => history("/products/billsnpay")}
                          className="menuIcon"
                        >
                          <div>
                            <NavList
                              title={"Billsnpay"}
                              description={
                                "Pay bills in a flash, save more and spend less"
                              }
                            />
                          </div>
                        </button>
                        <button
                          onClick={() => history("/products/finedge")}
                          className="menuIcon"
                        >
                          <div>
                            <NavList
                              title={"Finedge"}
                              description={"Integrated banking & payment automation solution"}
                            />
                          </div>
                        </button>
                     
                      </div>
                      <div>
                        <button
                          onClick={() => history("/products/smerp")}
                          className="menuIcon"
                          href="#h"
                        >
                          <div>
                            <NavList
                              title={"Smerp"}
                              description={"Transform your business process"}
                            />
                          </div>
                        </button>
                        <button
                          onClick={() => history("/products/kuleanpay")}
                          className="menuIcon"
                          href="#h"
                        >
                          <div>
                            <NavList
                              title={"Kuleanpay"}
                              description={"Protecting buyers and sellers on every transaction"}
                            />
                          </div>
                        </button>
                      </div>

                      <div>
                        <button
                          onClick={() => history("/products/ucp")}
                          className="menuIcon"
                          href="#h"
                        >
                          <div>
                            <NavList
                              title={"UCP"}
                              description={"Improve, Automate & Digitize Cooperative Operations"}
                            />
                          </div>
                        </button>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a
                  href="/blog"
                  className={props.url === "insights" ? "active" : ""}
                >
                  Insights
                </a>
              </li>
            </div>
          </div>

          <div className="mt-2 buttonSpace">
            <button
              onClick={() => showModal()}
              className="getStarted text-white"
            >
              Get Started
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className="mobilemenudisplay px-6 p-2 flex justify-between">
          <div className="mt-2">
            <img
              style={{ cursor: "pointer" }}
              src={logo}
              onClick={() => {
                history("/");
              }}
              alt="fifthlablogo"
            />
          </div>
          <div className="flex">
            <div className="px-2">
              <HiOutlineMenuAlt1 onClick={openNav} className="w-9 h-9" />
            </div>
          </div>
        </div>

        <div id="myNav" className="overlay">
          <div className="p-6 flex justify-between">
            <div className="mt-3">
              <img
                style={{ cursor: "pointer", display: "none" }}
                onClick={() => {
                  window.location.href = "/";
                }}
                src={Logo}
                alt="kickofflogo"
              />
            </div>
            <div className="mt-3">
              <MdClose onClick={closeNav} className="w-9 h-9 text-white" />
            </div>
          </div>
          <div className="overlay-content">
            <div className="mb-4">
            <button
              className={
                menuOpened
                  ? "animate__animated animate__fadeInDown animate__fast menuLink"
                  : "menuLink"
              }
              onClick={gotohome}
            >
              Home
            </button>
            </div>
            <div className="mb-4">
            <button
              className={
                menuOpened ? "animate__animated animate__fadeInDown menuLink" : "menuLink"
              }
              onClick={gotoabout}
            >
              About us
            </button>
            </div>

            <div className="mb-4">
            <button
              className={
                menuOpened
                  ? "animate__animated animate__fadeInDown animate__slower menuLink"
                  : "menuLink"
              }
              onClick={showProducts}
            >
             <span className="flex">  Products {productMenuOpened? <FiChevronUp style={{ margin: "5px" }} />:<FiChevronDown style={{ margin: "5px" }} />}</span>
            </button>
            </div>

            <ul id="productMenu" className="productMenu">
              <li ><button onClick={gotobillsnpay}>BillsnPay</button></li>
              <li><button onClick={gotosmerp}>Smerp</button></li>
              <li><button onClick={gotoucp}>UCP</button></li>
              <li><button onClick={gotofinedge}>Finedge</button></li>
              <li><button onClick={gotokuleanpay}>Kuleanpay</button></li>
            </ul>

            <div className="mb-4">
            <a
              className={
                menuOpened
                  ? "animate__animated animate__fadeInDown animate__slower menuLink"
                  : "menuLink"
              }
              onClick={closeNav}
              href="/blog"
            >
              Insights
            </a>

            </div>
            <div
              className={
                menuOpened
                  ? "animate__animated animate__fadeInDown animate__slower"
                  : null
              }
              
            >
              <button
                style={{ background: "#1DBCDB" }}
                onClick={() => showModal()}
                className="py-3 px-5 rounded-full text-white"
              >
                Get Started
              </button>
            </div>

            <div className="mt-4 flex justify-center"></div>
            <div className="mt-7 flex justify-center"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavMenu;
