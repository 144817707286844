import React from 'react'
import "./Products.css"
import Tabs from "./Tabs"


function Products() {
  

  return (
    <div id="products" className="productsContainer">
        <h2 className="updateText" data-aos="fade-up">Our products</h2>
        <p className="productDescription" data-aos="fade-up">We see simplified products and solutions as the foundation for growth and progress in commerce and society.</p>
        <div>
        <Tabs />
        </div>
    </div>
  )
}

export default Products