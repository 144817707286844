import React from 'react'
import "./About.css"
import AboutImg from "../../assets/images/aboutImg.png"


function AboutBanner() {
  return (
    <div >
        <div className="aboutTextContainer bannerFlex">
            <div className="aboutFlex">
            <div>
            <h2 className="leading-tight">
                We are <br/>Fifthlab
            </h2>
            </div>
            <div className="aboutDescription">
            <p>We are a company, but not the usual one. <br/>We solve problems, but not the usual way.</p>
           
            </div>
            <div className="hideImageMobile absolute right-0">
             <img src={AboutImg} alt="aboutImg"/>
            </div>
            </div>
        </div>

        

        

    </div>
  )
}

export default AboutBanner