import React from "react";
import "./Buttons.css";

function GetStarted(props) {
  function showModal() {
    document.getElementById("modalButton").click();
  }

  return (
    <button
      onClick={() => showModal()}
      className="demoButton"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
    >
      {props.name}{" "}
    </button>
  );
}

export default GetStarted;
