import React, { useEffect } from "react";
import NavMenu from "../../components/NavMenu/NavMenu";
import Info from "../../components/Info/Info";
import { Helmet } from "react-helmet";
import GeneralCookiePolicy from "../../components/CookiePolicy/CookiePolicy";
import ShowModal from "../../components/RequestDemo/ShowModal";

function CookiePolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cookie Policy | Fifthlab </title>
        <meta name="description" content="A subsidiary of CWG plc" />
        <link rel="canonical" href="https://thefifthlab.com" />
      </Helmet>

      <NavMenu url="home" className="navStyle" />
      <div className="bannerContainer ">
        <ShowModal />
      </div>

      <GeneralCookiePolicy />
      <Info />
    </div>
  );
}

export default CookiePolicy;
