import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import Smerp from "../../assets/images/smerp.png"


function Hero() { 

function gotowebsite(){
    window.open("https://smerp.ng","_blank")
}
  return (
    <div className="SmerpHero">
        <div className="SmerpText">
        <div className="smerpchip">Smerp</div>
        <h2>Transform your business process</h2>
        <div onClick={gotowebsite} className="flex websiteButton"><div>Visit Website</div> <div className="ml-4 mt-1" ><BsArrowRight /></div></div>
        </div>

        <div>
            <img  src={Smerp} alt="Smerp" />
        </div>
    </div>
  )
}

export default Hero