import React from 'react'

function TeamMemberCard(props) {
  return (
    <div className="teamImage">
        <img style={{objectFit:"cover"}} src={props.image} alt={props.name}/>
        <div className="text-center">
        <h2 className="font-bold text-xl mt-3">{props.name}</h2>
        <p className="text-xs">{props.role}</p>
        </div>
    </div>
  )
}

export default TeamMemberCard