import React,{useEffect} from 'react'
import NavMenu from '../../components/NavMenu/NavMenu'
import Hero from '../../components/Finedge/Hero'
import "./FinedgeHero.css"
import Features from '../../components/Finedge/Features'
import HowItWorks from '../../components/Finedge/HowItWorks'
import Info from '../../components/Info/Info'
import { Helmet } from "react-helmet";
import ShowModal from '../../components/RequestDemo/ShowModal'
function Smerp() {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])

  return (
    <div>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Finedge | Fifthlab </title>
        <meta name="description" content="Your finances at your fingertips" />
        <link rel="canonical" href="https://thefifthlab.com/products/finedge" />
      </Helmet>
        <NavMenu url={"products"}/>
        <div className="aboutBody">
        <Hero/>
        <Features/>
        <ShowModal />
        </div>
        <HowItWorks />
        <Info />

        
    </div>
  )
}

export default Smerp