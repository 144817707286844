import React from "react";
import TeamMemberCard from "./TeamMemberCard";
import Moradeke from "../../assets/images/about/Moradeke.png";
import Bamidele from "../../assets/images/about/Bamidele.png";
import Adaobi from "../../assets/images/about/Adaobi.png";
import Prosper from "../../assets/images/about/Prosper.png";
import Fawaz from "../../assets/images/about/Fawaz.png";
import Remi from "../../assets/images/about/Remi.png";
import Obinna from "../../assets/images/about/Obinna.png";
import Ayobami from "../../assets/images/about/Ayobami.png";
import Iyanu from "../../assets/images/about/Iyanu.png";
import Toheeb from "../../assets/images/about/Toheeb.png";
import Fego from "../../assets/images/about/Fego.png";
import Olanrewaju from "../../assets/images/about/Olanrewaju.png";
import Emeka from "../../assets/images/about/Emeka.png";
import Chima from "../../assets/images/about/Chima.png";

function Team() {
  return (
    <div>
      <div className="teamContainer teamHeading">
        <h2>Our team</h2>
        <p>
          Our team consists of a group of talented and creative people who work
          hard to solve real problems and change how people live and work. We're
          big enough to handle most challenges and small enough to care.
        </p>
      </div>

      <div id="team" className="teamMembers">
        <TeamMemberCard
          image={Moradeke}
          name={"Moradeke Akintola"}
          role={"Business Director, Platforms"}
        />
        <TeamMemberCard
          image={Bamidele}
          name={"Bamidele Ojekunle"}
          role={"Head, Software Development"}
        />
        <TeamMemberCard
          image={Adaobi}
          name={"Adaobi Obijiofor"}
          role={"Business Analyst"}
        />
        <TeamMemberCard
          image={Prosper}
          name={"Tochukwu Mgbemena"}
          role={"Product Manager"}
        />
        <TeamMemberCard
          image={Fawaz}
          name={"Fawwaz Alli-Balogun"}
          role={"Product Designer"}
        />
        <TeamMemberCard
          image={Remi}
          name={"Remi Odulate"}
          role={"Product Manager"}
        />
        <TeamMemberCard
          image={Obinna}
          name={"Obinna Opara"}
          role={"Product Support"}
        />
        <TeamMemberCard
          image={Ayobami}
          name={"Ayobami Awoyelu"}
          role={"Product Designer"}
        />
        <TeamMemberCard
          image={Iyanu}
          name={"Iyanuoluwa Opadotun"}
          role={"Software Developer"}
        />
        <TeamMemberCard
          image={Toheeb}
          name={"Toheeb Ojuolape"}
          role={"Software Engineer (Frontend)"}
        />
        <TeamMemberCard
          image={Fego}
          name={"OgheneFego Ogbegwa"}
          role={"Software Developer"}
        />
        <TeamMemberCard
          image={Olanrewaju}
          name={"Olanrewaju Shittu"}
          role={"Software Developer"}
        />
        <TeamMemberCard
          image={Emeka}
          name={"Emeka Uche"}
          role={"Software Developer"}
        />
        <TeamMemberCard
          image={Chima}
          name={"Chima Durumetu"}
          role={"QA Tester"}
        />
      </div>
    </div>
  );
}

export default Team;
