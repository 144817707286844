import React, { useState, useEffect } from "react";
import ArticleTitle from "../../components/Article/ArticleTitle";
import ArticleBody from "../../components/Article/ArticleBody";
import ArticleRelated from "../../components/Article/ArticleRelated";
import NavMenu from "../../components/NavMenu/NavMenu";
import ShowModal from "../../components/RequestDemo/ShowModal";
import { Helmet } from "react-helmet";
import "./Article.css";
import { formatBlogTitle } from "../../utils/formatting";
import fetchArticles from "../../api/api";
import ArticleSkeleton from "../../components/Loader/ArticleSkeleton/ArticleSkeleton";

function Article() {
  const [article, setArticle] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (JSON.parse(sessionStorage.getItem("article"))) {
      setArticle(JSON.parse(sessionStorage.getItem("article")));
    } else {
      const fetchData = async () => {
        try {
          const response = await fetchArticles();
          const article = response.filter(
            (article) =>
              article.guid ===
              "https://medium.com/p/" + window.location.pathname.split("/")[2]
          )[0];
          setArticle(article);
        } catch (error) {
          throw error;
        }
      };
      fetchData();
    }
  }, [setArticle]);

  return (
    <div className="bg-white">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {article ? formatBlogTitle(article.title) : ""} | Fifthlab
        </title>
        <meta name="description" content={article ? article.title : ""} />
        <link rel="canonical" href="https://thefifthlab.com" />
      </Helmet>

      <NavMenu className={"bg-white"} url={"insights"} />
      <div className="whiteContainer">
        <ShowModal />
      </div>

      {!article && (
        <div className="articleContainer aboutBody">
          <ArticleSkeleton />
        </div>
      )}
      {article && (
        <div className="articleContainer aboutBody">
          <ArticleTitle article={article} />
          <ArticleBody article={article} />
          <ArticleRelated />
        </div>
      )}
    </div>
  );
}

export default Article;
