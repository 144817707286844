import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import UCP from "../../assets/images/ucp.png"


function Hero() { 

function gotowebsite(){
    window.open("https://cooplatform.com.ng","_blank")
}
  return (
    <div className="ucpHero">
        <div className="ucpText">
        <div className="ucpchip">Unified Corporative Platform (UCP)</div>
        <h2>Improve, Automate & Digitize Cooperative Operations</h2>
        <div onClick={gotowebsite} className="flex websiteButton"><div>Visit Website</div> <div className="ml-4 mt-1" ><BsArrowRight /></div></div>
        </div>

        <div>
            <img  src={UCP} alt="ucp" />
        </div>
    </div>
  )
}

export default Hero