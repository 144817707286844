import React from 'react'
import Brand1 from "../../assets/images/brands/access-bank.svg"
import Brand2 from "../../assets/images/brands/bankly.svg"
import Brand3 from "../../assets/images/brands/firstbank.svg"
import Brand4 from "../../assets/images/brands/stanbic.svg"
import Brand6 from "../../assets/images/brands/itex.svg"
import Brand5 from "../../assets/images/brands/wemaBank.svg"

import "./Brands.css"

function Brands() {
  return (
    <div id="about"  className="mt-0 brandsContainer">
      <h2 className="brandTitle">we are currently used & trusted by</h2>
        <div className="brandsFlex">
            <img src={Brand1} alt="" />
            <img src={Brand2} alt="" />
            <img src={Brand3} alt="" />
            {/* <img src={Brand5} alt="" /> */}
            <img src={Brand6} className="mx-4" alt="" />
            <img src={Brand4} alt="" />
            <img src={Brand5} alt="" />
        </div>
    </div>
  )
}

export default Brands