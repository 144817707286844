import React from "react";

function ArticleBody({ article }) {
  return (
    <div className="article-body">
      <div dangerouslySetInnerHTML={{ __html: article.content }}></div>
    </div>
  );
}

export default ArticleBody;
