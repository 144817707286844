import React from 'react'

function NavList(props) {
  return (
    <div className="menuItemContainer">
        <h2 className="menuItemTitle">{props.title}</h2>
        <p className="menuItemDescription">{props.description}</p>
    </div>
  )
}

export default NavList