import React from 'react'
import Image from "../../../assets/images/headerBanner.png"
import "./bannerImage.css"


function bannerImage() {
  return (
    <div className="bannerImage">
        <img className="floating w-full z-10" src={Image} alt="" />
        </div>
  )
}

export default bannerImage