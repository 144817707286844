import React from "react";
import { formatBlogTitle } from "../../utils/formatting";

function Blog(props) {

  return (
    <div className="blogContainer" onClick={props.onClick}>
      <img
        style={{ objectFit: "cover", width: "350px", height: "300px" }}
        src={props.image}
        alt="img"
      />
      <div className="my-3 flex justify-between mr-5">
      <div className="chip">{props.categories[0]}</div>
        <li>{props.time}</li>
      </div>
      <h2 className="updateTitle">{formatBlogTitle(props.title)}</h2>
      <p>{props.author}</p>
    </div>
  );
}

export default Blog;
