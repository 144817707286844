import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import BillsnPay from "./pages/BillsNPay/BillsnPay";
import KuleanPay from "./pages/KuleanPay/KuleanPay";
import Smerp from "./pages/Smerp/Smerp";
import UCP from "./pages/UCP/UCP";
import Finedge from "./pages/Finedge/Finedge";
import SmerpPolicy from "./pages/Smerp/PrivacyPolicy";
import "./fonts/Fontspring-DEMO-neuealtegrotesk-black.otf";
import "./fonts/Fontspring-DEMO-neuealtegrotesk-bold.otf";
import "./fonts/Fontspring-DEMO-neuealtegrotesk-book.otf";
import "./fonts/Fontspring-DEMO-neuealtegrotesk-extralight.otf";
import "./fonts/Fontspring-DEMO-neuealtegrotesk-heavy.otf";
import "./fonts/Fontspring-DEMO-neuealtegrotesk-light.otf";
import "./fonts/Fontspring-DEMO-neuealtegrotesk-medium.otf";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy/CookiePolicy";
import CookiesBanner from "./components/CookiesBanner/CookiesBanner";
import Article from "./pages/Blog/Article";
import Footer from "./components/Footer/Footer";
import BlogPage from "./pages/Blog/BlogPage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/products/billsnpay" element={<BillsnPay />} />
          <Route path="/products/kuleanpay" element={<KuleanPay />} />
          <Route path="/products/smerp" element={<Smerp />} />
          <Route path="/products/ucp" element={<UCP />} />
          <Route path="/products/finedge" element={<Finedge />} />
          <Route path="/smerp/privacy-policy" element={<SmerpPolicy />} />
          <Route path="/blog/:id" element={<Article />} />
        </Routes>
      </BrowserRouter>
      {!localStorage.getItem("cookie-policy") && <CookiesBanner />}
      <Footer />
    </div>
  );
}

export default App;
