import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import Billsnpay from "../../assets/images/Billsnpay.png"

function Hero() { 

function gotowebsite(){
    window.open("https://billsnpay.com/","_blank")
}
  return (
    <div className="BillsnPayHero">
        <div className="billsnPayText">
        <div className="productchip">Billsnpay</div>
        <h2>Financial Services at your fingertip</h2>
        <div onClick={gotowebsite} className="flex websiteButton"><div>Visit Website</div> <div className="ml-4 mt-1" ><BsArrowRight /></div></div>
        </div>

        <div>
            <img className="floating" src={Billsnpay} alt="billsnpay" />
        </div>
    </div>
  )
}

export default Hero