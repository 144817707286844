import React from "react";
import DoMoreImage from "../../assets/images/domore.png";
import "./Mission.css"

function BeMore() {
  return (
    <div className="doMore">
        <img className="bemoreImage"  src={DoMoreImage} alt="" />
      <h2 className="beMoreTitle my-5">Do more</h2>
      <p className="my-6 textWidth">
        The reward for a great idea is the tools to make it happen. We provide you with financial and operational tools that help you do business differently and with ease
      </p>
      
    </div>
  );
}

export default BeMore;