import React from 'react'
import MissionCard from "./MissionCard"
import Target from "../../assets/images/about/target.png"
import Eyes from "../../assets/images/about/eyes.png"

function Mission() {
  return (
    <div style={{background:"#EDFAFC"}}>
        <div className="aboutBody">
            <div className="missionFlex">
            <MissionCard image={Target} title={"Mission"} description={"To create simplified solutions that make life and work easy"}/>
            <MissionCard image={Eyes} title={"Vision"} description={"To build an ecosystem of solutions that work for people"}/>
            </div>
        </div>
    </div>
  )
}

export default Mission