import "./Skeleton.css";
const SkeletonLoader = ({ width, height, border }) => {
  return (
    <div
      className="skeleton-loader my-2"
      style={{ maxWidth: width, height: height, borderRadius: border }}
    >
      <div className="skeleton-loader__content"></div>
    </div>
  );
};

export default SkeletonLoader;
