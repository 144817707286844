import React from "react";
import BeMoreImage from "../../assets/images/bemore.png";
import "./Mission.css"

function BeMore() {
  return (
    <div className="beMore mb-9">
      <h2 className="beMoreTitle">Be more</h2>
      <p className="my-6 textWidth">
        Live your best life, create more and multiply your impact with  the tools
        and solutions we provide.
      </p>
      <img className="bemoreImage" src={BeMoreImage} alt="" />
    </div>
  );
}

export default BeMore;
