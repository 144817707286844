import React from 'react'

function Step(props) {
  return (
    <div className="stepContainer">
        <h2>{props.title}</h2>
        <p>{props.description}</p>
    </div>
  )
}

export default Step