import axios from "axios";
import { FIFTHLAB_MEDIUM_URL } from "../constants/constants";

const fetchArticles = async () => {
  try {
    let response = await axios({
      method: "GET",
      url:
        "https://api.rss2json.com/v1/api.json?rss_url=" + FIFTHLAB_MEDIUM_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.items;
  } catch (error) {
    throw error;
  }
};

export default fetchArticles;
