import React from 'react'
import FeaturesCard from "./FeaturesCard"

function Features() {
  return (
    <div className="featureGrid">
        <FeaturesCard tag={"Quick and Convenient"} title={"Fast money transfer"} description={"Send money to family and friends within seconds with the Billsnpay App."} />
        <FeaturesCard tag={"Never miss a bill"} title={"Pay bills in a flash"} description={"Make payments and renew all your bills - Cable TV, utility, etc easily with BillsnPay bills Payment services."} />
        <FeaturesCard tag={"Easy invoice generation"} title={"Send invoices/Bills"} description={"Create track and send unlimited invoices , receipts, and automatic reminders for your invoices."} />
        <FeaturesCard tag={"Have fun in-app"} title={"Games Subscription"} description={"Try out some games for free and enjoy the lowest subscription prices with over 250 games available."} />
        <FeaturesCard tag={"Join our crew"} title={"Earn as an agent"} description={"Earn extra income as a BillsnPay agent, sign up today. Get the best commissions on Airtime, Data, and bills payment."} />
    </div>
  )
}

export default Features