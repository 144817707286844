import React from 'react'
import Step from "./Step"

function Steps() {
  return (
    <div className="stepGrid">
        <Step title={"Step 1"} description={"Download the BillsnPay Mobile app from Google Play or App store or Click on the Signup button"} />
        <Step title={"Step 2"} description={"Submit requested registration details and get verified"} />
        <Step title={"Step 3"} description={"Start buying Airtime, loading your Wallet, paying your Bills and enjoy a lifetime of ease and convenience."} />
    </div>
  )
}

export default Steps