import React from 'react'
import FeaturesCard from "../BillsnPay/FeaturesCard"

function Features() {
  return (
    <div className="featureGrid">
        <FeaturesCard tag={"Secure Transactions"} title={"Buyer & seller security"} description={"Buyers and sellers are protected with funds held in a third party escrow. Payments are released only after orders are fulfilled."} />
        <FeaturesCard tag={"Loans"} title={"Financing opportunities"} description={"We provide financing options for our buyers and sellers on our own net terms while increasing their cash flow."} />
        <FeaturesCard tag={"Partnership"} title={"Partnership opportunities"} description={"Make money when you partner with Kuleanpay as you leverage our world class API to help you to grow your business and accept safe payments. With just a few lines of code, you can quickly create safe, quick services and start taking payments."} />
        <FeaturesCard tag={"Milestones"} title={"Track transaction"} description={"Track your transaction every step of the way. Your money will be held securely until you’re satisfied with the services you received."} />
    </div>
  )
}

export default Features