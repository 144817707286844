import React from 'react'
import FeaturesCard from "../BillsnPay/FeaturesCard"

function Features() {
  return (
    <div className="featureGrid">
        <FeaturesCard tag={"Programs"} title={"Reward & loyalty program"} description={"Condition-based perks and rewards that will automatically be assigned to customers"} />
        <FeaturesCard tag={"Orders"} title={"Automated order management"} description={"Automating each stage of the order work-flow from start to fulfillment."} />
        <FeaturesCard tag={"Inventory"} title={"Inventory management"} description={"Structure, manage and keep track of inventory items reliably and efficiently."} />
        <FeaturesCard tag={"Accounts"} title={"Accounting management"} description={"Track and manage expenditures, credits, revenues and profits with SMERP"} />
        <FeaturesCard tag={"Cusomers"} title={"Customer management"} description={"Intelligently and efficiently manage leads, opportunities and much more from your customers."} />
        <FeaturesCard tag={"Invoice"} title={"Invoicing management"} description={"Eliminate human error while identifying price-friendly vendors that meet your business needs."} />

    </div>
  )
}

export default Features