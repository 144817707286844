import React, { useState } from "react";
import "./Tabs.css";
import TabCard from "./TabCard";
import BillsNPay from "../../assets/images/BillsNPay.svg";
import BillsLogo from "../../assets/images/brands/image 2.svg";
import SmerpLogo from "../../assets/images/brands/image 9.svg";
import Smerp from "../../assets/images/brands/Smerp.svg";
import UCPLogo from "../../assets/images/brands/image 11.svg";
import UCP from "../../assets/images/brands/UCP.svg";
import KuleanPayLogo from "../../assets/images/brands/KuleanpayLogo.svg";
import KuleanPay from "../../assets/images/brands/Kulean.svg";
import Finedge from "../../assets/images/brands/Finedge.svg";
import Brand6 from "../../assets/images/brands/Rectangle.svg";

function Tabs() {
  const [tab, setTab] = useState("billsnpay");

  return (
    <div className="tabsContainer my-5">
      <ul
        className="tabSelect mx-auto max-w-2xl nav nav-tabs flex flowOver list-none"
        id="tabs-tab3"
        role="tablist"
      >
        <li className="" role="presentation">
          <a
            href="#tabs-home3"
            className={
              tab === "billsnpay"
                ? "tabsActive block text-xs px-6 py-3 my-2"
                : "block text-xs px-6 py-3 my-2"
            }
            id="tabs-home-tab3"
            data-bs-toggle="pill"
            data-bs-target="#tabs-home3"
            role="tab"
            aria-controls="tabs-home3"
            aria-selected="true"
            onClick={() => setTab("billsnpay")}
          >
            BillsnPay
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-profile3"
            className={
              tab === "smerp"
                ? "tabsActive text-gray-600 w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-0 border-transparent px-6 py-3 my-2 text-white hover:border-transparent hover:bg-gray-100"
                : "text-gray-600 w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-0 border-transparent px-6 py-3 my-2 text-white hover:border-transparent hover:bg-gray-100"
            }
            id="tabs-profile-tab3"
            data-bs-toggle="pill"
            data-bs-target="#tabs-profile3"
            role="tab"
            aria-controls="tabs-profile3"
            aria-selected="false"
            onClick={() => setTab("smerp")}
          >
            Smerp
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-messages4"
            className={
              tab === "ucp"
                ? "tabsActive text-gray-600 w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-0 border-transparent px-6 py-3 my-2 text-white hover:border-transparent hover:bg-gray-100"
                : "text-gray-600 w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-0 border-transparent px-6 py-3 my-2 text-white hover:border-transparent hover:bg-gray-100"
            }
            id="tabs-messages-tab4"
            data-bs-toggle="pill"
            data-bs-target="#tabs-messages4"
            role="tab"
            aria-controls="tabs-messages4"
            aria-selected="false"
            onClick={() => setTab("ucp")}
          >
            UCP
          </a>
        </li>

        <li className="nav-item" role="presentation">
          <a
            href="#tabs-messages5"
            className={
              tab === "finedge"
                ? "tabsActive text-gray-600 w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-0 border-transparent px-6 py-3 my-2 text-white hover:border-transparent hover:bg-gray-100"
                : "text-gray-600 w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-0 border-transparent px-6 py-3 my-2 text-white hover:border-transparent hover:bg-gray-100"
            }
            id="tabs-messages-tab5"
            data-bs-toggle="pill"
            data-bs-target="#tabs-messages5"
            role="tab"
            aria-controls="tabs-messages5"
            aria-selected="false"
            onClick={() => setTab("finedge")}
          >
            Finedge
          </a>
        </li>

        <li className="nav-item" role="presentation">
          <a
            href="#tabs-messages6"
            className={
              tab === "kuleanpay"
                ? "tabsActive text-gray-600 w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-0 border-transparent px-6 py-3 my-2 text-white hover:border-transparent hover:bg-gray-100"
                : "text-gray-600 w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-0 border-transparent px-6 py-3 my-2 text-white hover:border-transparent hover:bg-gray-100"
            }
            id="tabs-messages-tab6"
            data-bs-toggle="pill"
            data-bs-target="#tabs-messages6"
            role="tab"
            aria-controls="tabs-messages6"
            aria-selected="false"
            onClick={() => setTab("kuleanpay")}
          >
            Kuleanpay
          </a>
        </li>
      </ul>

      <div className="tab-content" id="tabs-tabContent3">
        <div
          className="tab-pane fade show active"
          id="tabs-home3"
          role="tabpanel"
          aria-labelledby="tabs-home-tab3"
        >
          <TabCard
            url="/products/billsnpay"
            image={BillsNPay}
            title="Bill presentment & Payment"
            logo={BillsLogo}
            name="Billsnpay"
            description="BillsNPay is a state-of-the-art unified bill presentation and payment platform which allows B2C and B2B billers to present a safe, secure and easy-to-use hub for customers to view bills."
          />
        </div>
        <div
          className="tab-pane fade"
          id="tabs-profile3"
          role="tabpanel"
          aria-labelledby="tabs-profile-tab3"
        >
          <TabCard
            url="/products/smerp"
            image={Smerp}
            title="Manage business operations"
            logo={SmerpLogo}
            name="Smerp"
            description="This is an Enterprise Resource Planning platform built to enable Small and Medium Enterprises (SMEs) manage their business operations efficiently."
          />
        </div>
        <div
          className="tab-pane fade"
          id="tabs-messages4"
          role="tabpanel"
          aria-labelledby="tabs-profile-tab4"
        >
          <TabCard
            url="/products/ucp"
            name="UCP"
            image={UCP}
            title="Improve, Automate & Digitize Cooporative Operations"
            logo={UCPLogo}
            description="Unified Cooperative Platform is a core cooperative operations software in patnership with CFAN, to meet the rising and dynamic needs of Cooperative Societies across Africa."
          />
        </div>
        <div
          className="tab-pane fade"
          id="tabs-messages5"
          role="tabpanel"
          aria-labelledby="tabs-profile-tab5"
        >
          <TabCard
            image={Finedge}
            title="Micro Banking"
            logo={Brand6}
            name="Finedge"
            url="/products/finedge"
            description="Mobile Money Operators, Microfinance Banks, Cooperative Societies, Banks and other financial institutions can now automate their processes on a single platform using FinEdge Core Banking Application. "
          />
        </div>
        <div
          className="tab-pane fade"
          id="tabs-messages6"
          role="tabpanel"
          aria-labelledby="tabs-profile-tab6"
        >
          <TabCard
            url="/products/kuleanpay"
            name="Kuleanpay"
            image={KuleanPay}
            title="Managing risk for transacting parties"
            logo={KuleanPayLogo}
            description="This is a fast, trusted, and convenient payment platform that help SMEs to solve the issues around credit risk, fraud and trust when transacting business with third-party. KuleanPay provides a secure platform (leveraging the concept of Escrow) that completely protects both transacting parties."
          />
        </div>
      </div>
    </div>
  );
}

export default Tabs;
