import React from 'react'

function MissionCard(props) {
  return (
    <div className="missionCard">
        <img src={props.image} alt="img"/>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
    </div>
  )
}

export default MissionCard