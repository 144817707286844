import React from "react";
import ArticleAuthor from "./ArticleAuthor";

function ArticleTitle({article}) {
  return (
    <div>
      <h1 className="articleTitle">
       {article.title}
      </h1>

      <ArticleAuthor article={article}/>
    </div>
  );
}

export default ArticleTitle;
