import React from 'react'
import "./info.css"


export default function Info() {
 

  return (
    <div>
<div
  className="p-12 relative overflow-hidden bg-no-repeat bg-cover"
  style={{height: "400px",background:"#000000"}}

>
  <div
    className="infoContainer absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
  >
    <div className="infoFlex items-center h-full">
      <div className="infoText">
        <h2 style={{color:"#00B4D8"}} className="font-semibold lg:text-5xl infoHeading p-3 mb-4">Want to learn more, 
see our platform in action or request a demo?</h2>
      </div>

      <div>
      <button

        className="inline-block text-black buttonPadding mb-1 bg-white rounded-full border-gray-200  font-medium text-sm leading-snug rounded hover:shadow-xl focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        data-bs-toggle="modal" data-bs-target="#exampleModal"
        
        data-mdb-ripple="true"
        data-mdb-ripple-color="light"
        target="_blank" 
        rel="noreferrer"
          ><span>Book A Demo </span></button>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}
