import React from 'react'
import Step from "../BillsnPay/Step"

function Steps() {
  return (
    <div className="stepGrid">
        <Step title={"Step 1"} description={"Download the UCP app from respective app store or create a free account on our web app"} />
        <Step title={"Step 2"} description={"Provide relevant information or preview transaction details by creating or joining transactions respectively."} />
        <Step title={"Step 3"} description={"Fund wallet, track transaction progress, confirm delivery and make withdrawals."} />
    </div>
  )
}

export default Steps