import React from "react";

function BusinessCategory(props) {
  return (
    <div className="businessCategory">
      <img src={props.image} alt="icons" />
      <p className="my-3">{props.title}</p>
    </div>
  );
}

export default BusinessCategory;
