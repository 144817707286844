import React, { useEffect } from "react";
import NavMenu from "../../components/NavMenu/NavMenu";
import AboutBanner from "./AboutBanner";
import AboutFlexCard from "../../components/AboutComponents/AboutFlexCard";
import WhatWeDo from "../../components/AboutComponents/WhatWeDo";
import Mission from "../../components/AboutComponents/Mission";
import BrandValueFlexCard from "../../components/AboutComponents/BrandValueFlexCard";
import Team from "../../components/AboutComponents/Team";
import Info from "../../components/Info/Info";
import { Helmet } from "react-helmet";
import ShowModal from "../../components/RequestDemo/ShowModal";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About us | Fifthlab </title>
        <meta name="description" content="Learn about the work we do" />
        <link rel="canonical" href="https://thefifthlab.com/about" />
      </Helmet>

      <NavMenu url="about" />
      <div className="aboutContainer">
        <AboutBanner />
        <ShowModal />
      </div>
      <div className="aboutBody">
        <AboutFlexCard />
        <WhatWeDo />
      </div>

      <Mission />

      <div className="aboutBody">
        <BrandValueFlexCard />
        <Team />
      </div>
      <Info />
    </div>
  );
}

export default About;
