import React from "react";
import "./CookiePolicy.css";

function PrivacyPolicy() {
  return (
    <div>
      <div className="titleContainer">
        <h1>Cookie Policy</h1>
      </div>

      <div className="privacyPolicy">
        <h1 className="font-bold text-xl">1. Introduction</h1>
        <p>
          This Cookies Notice explains what Cookies are and how We use them. You
          should read this Notice so You can understand what type of cookies We
          use, or the information We collect using Cookies and how that
          information is used. Cookies do not typically contain any information
          that personally identifies a user, but personal information that we
          store about You may be linked to the information stored in and
          obtained from Cookies.
        </p>

        <p>
          For further information on how We use, store and keep your personal
          data secure, see our Privacy Notice. We do not store sensitive
          personal information, such as mailing addresses, account, passwords,
          etc. in the Cookies We use.
        </p>

        <div>
          <h1 className="font-bold text-xl">
            2. Interpretation and Definitions
          </h1>
          <p>
            Cookies are small text files that may be sent to and registered on
            your computer by the websites you visit, to then be re-sent to those
            same sites when you visit them again. It is thanks to these cookies
            that those websites can “remember” your actions and preferences
            (e.g., login data, language, font size, other display settings,
            etc.), so that you do not need to configure them again when you next
            visit the website, or when you change pages within a website.
          </p>

          <p>
            Cookies are used for electronic authentication, monitoring of
            sessions, and storage of information regarding your activities when
            accessing a website. They may also contain a unique ID code that
            allows tracking of your browsing activities within a website, for
            statistical or advertising purposes. Some operations within a
            website may not be able to be performed without the use of cookies
            which, in certain cases, are technically necessary for the operation
            of the website.
          </p>

          <p>
            When browsing a website, you may also receive cookies from websites
            or web servers other than the website being visited (i.e.,
            “third-party cookies”). There are various types of cookies,
            depending on their characteristics and functions, which may be
            stored on your computer for different periods of time: “session
            cookies”, which are automatically deleted when you close your
            browser, and “persistent cookies”, which will remain on your device
            until their pre-set expiration period passes.
          </p>

          <p>
            According to the law which may be applicable to you, your consent
            may not always be necessary for cookies to be used on a website. In
            particular, “technical cookies” – i.e. cookies which are only used
            to send messages through an electronic communications network, or
            which are needed to provide services you request – typically do not
            require this consent. This includes browsing or session cookies
            (used to allow users to login) and function cookies (used to
            remember choices made by a user when accessing the website, such as
            language or products selected for purchase).
          </p>

          <p>
            On the other hand, “profiling cookies” – i.e., cookies used to
            create profiles on users and to send advertising messages in line
            with the preferences revealed by users while browsing websites –
            typically require specific consent from users, although this may
            vary according to the applicable law.
          </p>

          <p>
            For the purposes of this Cookies Notice: “Company” (referred to as
            either “the Company”, “We”, “Us” or “Our” in this Cookies Policy)
            refers to FIFTHLAB, Plot 10, Joshua Ebun Ojo Off Rufus Giwa, Lekki
            Phase 1, Lagos Nigeria. “Cookies” means small files that are placed
            on Your computer, mobile device or any other device by a website,
            containing details of your browsing history on that website among
            its many uses. “Website” refers to FIFTHLAB, accessible from
            https://thefifthlab.com/ “You” means the individual accessing or
            using the Website, or a company, or any legal entity on behalf of
            which such individual is accessing or using the Website, as
            applicable.
          </p>
        </div>

        <div>
          <h1 className="font-bold text-xl">3. The use of the Cookies</h1>
          <p>The Website use the following types of cookies:</p>

          <ul>
            <li>
              • Browsing or session cookies, which are strictly necessary for
              the Website’s operation, and/or to allow you to use the Website’s
              content and Services.
            </li>
            <li>
              • Analytics cookies, which allow Cookies to understand how users
              make use of the Website, and to track traffic to and from the
              Website.
            </li>

            <li>
              • Function cookies, which are used to activate specific Website
              functions and to configure the Website according to your choices
              (e.g., language), in order to improve your experience.
            </li>

            <li>
              • Profiling cookies, which are used to observe the preferences you
              reveal through your use of the Website and to send you advertising
              messages in line with those preferences.
            </li>
          </ul>

          <p>
            The Cookies We use are referred to as “first-party cookies.” and
            those that are used by our partners are “third-party cookies”.
            Because of how cookies work, our website cannot access third-party
            cookies; nor can other organisations access the data in the cookies
            we use on our website.
          </p>

          <h2 className="text-lg font-bold">Cookies present on the Website</h2>
          <p>
            In detail, the first-party and third party cookies present on the
            Website are as follows:
          </p>

          <table>
            <thead>
              <td>Domain</td>
              <td>Name</td>
              <td>Expired at</td>
            </thead>
          </table>
          <div className="text-center my-3">First Party Cookies</div>

         <table>
            <tbody>
              <tr>
                <td>thefifthlab.com</td>
                <td>cf7msm_check</td>
                <td>Session</td>
              </tr>

              <tr>
                <td>thefifthlab.com</td>
                <td>wplc_cid</td>
                <td>24 hours</td>
              </tr>

              <tr>
                <td>thefifthlab.com</td>
                <td>tcx_customerID</td>
                <td>1 year</td>
              </tr>

              <tr>
                <td>thefifthlab.com</td>
                <td>nc_sid</td>
                <td>Session</td>
              </tr>

              <tr>
                <td>thefifthlab.com</td>
                <td>nc_status</td>
                <td>24 hours</td>
              </tr>

              <tr>
                <td>thefifthlab.com</td>
                <td>_icl_current_languag...</td>
                <td>24 hours</td>
              </tr>

              <tr>
                <td>thefifthlab.com</td>
                <td>wplc_chat_status</td>
                <td>24 hours</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h1 className="font-bold text-xl">
            4. How to Manage and Delete Your Cookies
          </h1>
          <p>
            You can block or delete cookies used on the Website via your browser
            options. Your cookie preferences will be reset if different browsers
            are used to access the Website. For more information on how to set
            the preferences for cookies via your browser, please refer to the
            following instructions:
          </p>

          <ul>
            <li>• Internet Explorer Firefox</li>
            <li>• Chrome</li>
            <li>• Safari</li>
          </ul>

          <p>
            CAUTION: If you block or delete technical and/or function cookies
            used by the Website, the Website may become impossible to browse,
            certain services or functions of the Website may become unavailable
            or other malfunctions may occur. In this case, you may have to
            modify or manually enter some information or preferences every time
            you visit the Website.
          </p>

          <h1 className="font-bold text-xl">
            5. Underpinning policies & procedures
          </h1>
          <p>
            You can block or delete cookies used on the Website via your browser
            options. Your cookie preferences will be reset if different browsers
            are used to access the Website. For more information on how to set
            the preferences for cookies via your browser, please refer to the
            following instructions:
          </p>

          <ul>
            <li>• Internet Explorer Firefox</li>
            <li>• Chrome</li>
            <li>• Safari</li>
          </ul>

          <p>
            CAUTION: If you block or delete technical and/or function cookies
            used by the Website, the Website may become impossible to browse,
            certain services or functions of the Website may become unavailable
            or other malfunctions may occur. In this case, you may have to
            modify or manually enter some information or preferences every time
            you visit the Website.
          </p>

          <div>
            <h1 className="font-bold text-xl">
              6. Withdraw Your Cookies Consent at Any Time
            </h1>
            <p>
              You can block or delete cookies used on the Website via your
              browser options. Your cookie preferences will be reset if
              different browsers are used to access the Website. For more
              information on how to set the preferences for cookies via your
              browser, please refer to the following instructions:
            </p>

            <p>
              If you do not wish to accept cookies in connection with your use
              of our website, you may delete and block or disable cookies via
              your browser settings; see below for more information on how to do
              this.
            </p>

            <p>
              If you have any questions or complaint about this Cookies Policy,
              you can contact us by email at{" "}
              <a className="brandColor" href="mailto:dpo@thefifthlab.com">
                dpo@thefifthlab.com
              </a>
            </p>

            <p>
              For the purpose of this Notice, the supervisory authority is the
              National Information
            </p>

            <p>
              Technology Development Agency (NITDA) and the complaint can be
              sent via email at{" "}
              <a
                className="brandColor"
                target={"_blank"}
                rel="noreferrer"
                href="dpo@nitda.gov.ng"
              >
                dpo@nitda.gov.ng
              </a>
            </p>
          </div>

          <div>
            <h1 className="font-bold text-xl">7. Approval</h1>
            <p>
              This policy has been approved by the management of Fifthlab and
              will be reviewed at least annually.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
