import React from "react";
import BusinessCategory from "./BusinessCategory";
import B2B from "../../assets/images/about/B2B.svg"
import B2C from "../../assets/images/about/B2C.svg"
import B2B2C from "../../assets/images/about/B2B2C.svg"



function WhatWeDo() {
  return (
    <div className="whatWeDoContainer">
      <h2>What we do</h2>
      <p style={{color:"#4B5563"}}>
        We serve as an intermediary between technology and the people by
        providing dynamic solutions to business and everyday challenges and most
        importantly simplify operations. <br/> <br/> We deploy working and effective
        technologies to do the hardwork and present the easy one to you. We've
        spent years researching the best way to solve problems with people and
        businesses in mind. We pride ourselves on our ability to deliver a
        premium user experience and look forward to proving it to you. 
        <br/><br/>
        We serve
        businesses in 3 categories:
      </p>

      <div className="customerCategory">
        <BusinessCategory image={B2B} title={"Business to Business"} />

        <BusinessCategory image={B2C} title={"Business to Customer"} />

        <BusinessCategory image={B2B2C} title={"Business to Business to Customer"} />
      </div>
    </div>
  );
}

export default WhatWeDo;
