import React from "react";
import SkeletonLoader from "../Skeleton";

function ArticleAuthor() {
  return (
    <div className="my-2">
      <SkeletonLoader width={"50px"} height={"50px"} border={"50%"} />
    </div>
  );
}

export default ArticleAuthor;
