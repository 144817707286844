import React from "react";
import ArticleAuthor from "./ArticleAuthor";
import SkeletonLoader from "../Skeleton";

function ArticleTitle() {
  return (
    <div>
      <h1 className="articleTitle">
       <SkeletonLoader height={"20px"} width={"700px"} border={"5px"}/>
       <SkeletonLoader height={"15px"} width={"500px"} border={"5px"}/>
      </h1>

      <ArticleAuthor/>
    </div>
  );
}

export default ArticleTitle;
