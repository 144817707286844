import React from "react";
import BrandValues from "../../assets/images/about/brandValue.png";

function BrandValueFlexCard() {
  return (
    <div className="aboutCardFlex">
      <div>
        <img src={BrandValues} alt="" />
      </div>
      <div className="aboutCardText">
        <h2>Our brand values</h2>
        <p style={{ color: "#4B5563" }}>
          Our value system shapes our culture. Our culture in turn, plays a huge
          role in shaping our products and how we see and interact with work and
          people.
        </p>

        <div style={{color:"#4B5563",margin:"28px 0px"}} className="valuesFlex">
          <div>
            <li className="mb-5">Optimism</li>

            <li className="mb-5">Respect</li>
          </div>

          <div>
            <li className="mb-5">Customer Obsession</li>

            <li className="mb-5">Can-do Spirit</li>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandValueFlexCard;
