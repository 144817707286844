import React from 'react'
import "./Mission.css"
import BeMore from './BeMore'
import DoMore from "./DoMore"



function Mission() {

  
  return (
    <div className="missionContainer">
      <BeMore />
      <DoMore />
    </div>
  )
}

export default Mission