import React from 'react'
import FeaturesCard from "../BillsnPay/FeaturesCard"

function Features() {
  return (
    <div className="featureGrid">
        <FeaturesCard tag={"Core Banking"} title={"Deposits"} description={"You can get access to different account types such as current and savings, term deposits, top up deposits, recurring deposits and fixed rate deposits"} />
        <FeaturesCard tag={"Core Banking"} title={"Lending"} description={"You have access to personal loans, vehicle loans, student loans, home loans, school loans, overdrafts, group loans, term loans and restructuring."} />
        <FeaturesCard tag={"Core Banking"} title={"Placements"} description={"You have access to call placement, Fixed placements, treasury bills and commercial papers"} />
        <FeaturesCard tag={"Core Banking"} title={"Banking Operations"} description={"You have access to cash withdrawals, cash deposit, cheque management, bulk upload and remittance posting"} />

    </div>
  )
}

export default Features