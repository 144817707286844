import React from 'react'
import FeaturesCard from "../BillsnPay/FeaturesCard"

function Features() {
  return (
    <div className="featureGrid">
        <FeaturesCard tag={"Programs"} title={"Member management"} description={"UCP keeps accurate records of the members, with ease of retrieval at any point in time."} />
        <FeaturesCard tag={"Orders"} title={"Contribution management"} description={"UCP allows cooperative members to pay and view their contribution in real-time."} />
        <FeaturesCard tag={"Inventory"} title={"Financing opportunities"} description={"Cooperative societies and cooperators can now have access to grants and loans"} />
        <FeaturesCard tag={"Accounts"} title={"Report generation"} description={"Cooperatives registered on the platform can spool management information"} />
       

    </div>
  )
}

export default Features