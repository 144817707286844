import React from 'react'
import aboutCardFlex from "../../assets/images/about/aboutCardImage.png"

function AboutFlexCard() {
  return (
    <div className="aboutCardFlex">
        <div>
        <img src={aboutCardFlex} alt=""/>
        </div>
        <div className="aboutCardText">
        <h2>Who we are</h2>
        <p style={{color:"#4B5563"}}>We are an ecosystem of advanced but simple solutions with the core focus of delivering client-centered products and services that make our clients’ lives and daily operations easy. We believe that business and life should not be harder than it already appears. We are dynamic, competent, inventive and inclusive. </p>
        </div>
    </div>
  )
}

export default AboutFlexCard