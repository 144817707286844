import React from "react";
import kuleanpay from "../../assets/images/kuleanpay.png";
import { BsArrowRight } from 'react-icons/bs'

function Hero() {
  function gotowebsite() {
    window.open("https://kuleanpay.com", "_blank");
  }
  return (
    <div className="kuleanPayHero">
      <div className="kuleanchip mx-auto">Kuleanpay</div>
      <h2>Protecting both parties on all transactions</h2>
      <div onClick={gotowebsite} className="mx-auto flex justify-center websiteButton">
        <div>Visit Website</div>{" "}
        <div className="ml-4 mt-1">
          <BsArrowRight />
        </div>
      </div>
      <img src={kuleanpay} alt="kuleanpay" />
    </div>
  );
}

export default Hero;
