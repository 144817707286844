import React from 'react'
import ArticleTitle from './ArticleTitle'
import ArticleBody from './ArticleBody'

function ArticleSkeleton() {
  return (
    <div>
       <ArticleTitle />
       <ArticleBody />
    </div>
  )
}

export default ArticleSkeleton