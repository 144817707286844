import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import finedge from "../../assets/images/finedge.png"


function Hero() { 

function gotowebsite(){
    window.open("https://finedgesoftware.com","_blank")
}
  return (
    <div className="finedgeHero">
        <div className="finedgeText">
        <div className="productchip">Finedge</div>
        <h2>Your finances at your fingertips</h2>
        <div onClick={gotowebsite} className="flex websiteButton"><div>Visit Website</div> <div className="ml-4 mt-1" ><BsArrowRight /></div></div>
        </div>

        <div>
            <img  src={finedge} alt="finedge" />
        </div>
    </div>
  )
}

export default Hero