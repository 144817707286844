import React, { useEffect } from "react";
import NavMenu from "../../components/NavMenu/NavMenu";
import KuleanPayHero from "../../components/KuleanPay/Hero";
import "./KuleanPayHero.css";
import Features from "../../components/KuleanPay/Features";
import HowItWorks from "../../components/KuleanPay/HowItWorks";
import Info from "../../components/Info/Info";
import { Helmet } from "react-helmet";
import ShowModal from "../../components/RequestDemo/ShowModal";

function KuleanPay() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Protecting both parties on all transactions | Fifthlab </title>
        <meta
          name="title"
          content="Protecting both parties on all transactions | Fifthlab"
        />
        <meta
          name="description"
          content="Protect yourself from fraudulent transactions with Kuleanpay"
        />
        <link
          rel="canonical"
          href="https://thefifthlab.com/products/kuleanpay"
        />
      </Helmet>

      <NavMenu url={"products"} />
      <div className="aboutBody">
        <KuleanPayHero />
        <Features />
        <ShowModal />
      </div>
      <HowItWorks />
      <Info />
    </div>
  );
}

export default KuleanPay;
