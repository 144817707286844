import React from 'react'
import "./Home.css"
import BannerImage from './BannerImage/bannerImage'
import Button from "../../components/Buttons/Button"


function Banner() {
  return (
    <div data-aos="fade-up" id="home" className="bannerFlex">
        <div className="ecosystemContainer pr-9 ">
            <h2 className="ecosystemText">
                Ecosystem of Solutions that work
            </h2>
            <p className="animate__animated animate__fadeInUp captionText">User-centric solutions that make life and business easy.</p>
       <div className="mt-7 animate__animated animate__fadeInUp animate__delay-1s">
       <Button name="Book A Demo" data-bs-toggle="modal" data-bs-target="#exampleModal"/>
       </div>
        </div>

        <div className="animate__animated animate__fadeInUp lg:w-1/2">
          <BannerImage />
        </div>

     

        

    </div>
  )
}

export default Banner