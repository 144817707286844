import React, { useEffect } from "react";
import "./BillsnPay.css";
import NavMenu from "../../components/NavMenu/NavMenu";
import BillsnPayHero from "../../components/BillsnPay/Hero";
import Features from "../../components/BillsnPay/Features";
import HowItWorks from "../../components/BillsnPay/HowItWorks";
import Info from "../../components/Info/Info";
import { Helmet } from "react-helmet";
import ShowModal from "../../components/RequestDemo/ShowModal";

function BillsnPay() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Financial Services at your fingertip | Fifthlab </title>
        <meta
          name="description"
          content="Financial Services at your fingertip with BillsNpay"
        />
        <link
          rel="canonical"
          href="https://thefifthlab.com/products/billsnpay"
        />
      </Helmet>

      <NavMenu url={"products"} />
      <div className="aboutBody">
        <BillsnPayHero />
        <Features />
        <ShowModal />
      </div>
      <HowItWorks />
      <Info />
    </div>
  );
}

export default BillsnPay;
