import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import "./CookieBanner.css";
import SecondaryButton from "../Buttons/SecondaryButton";

function CookiesBanner() {
  const acceptCookie = () => {
    localStorage.setItem("cookie-policy", true);
    document.getElementById("cookie-banner").style.display = "none";
  };
  const goToPrivacy = () => {
    window.location.href = "/cookie-policy";
  };
  return (
    <div id="cookie-banner" className="cookieContainer animate__animated animate__fadeInUp">
      <div className="cookieBanner">
        <p className="cookieText">
          We use cookies on our website to improve your user experience. You can
          accept or manage your cookie preferences. To learn more about our use
          of cookies,{" "}
          <a className="underline" href="/cookie-policy">
            view our cookie policy
          </a>
        </p>

        <div className="cookieButton">
          <PrimaryButton onClick={acceptCookie} title={"Accept"} />{" "}
          <SecondaryButton onClick={goToPrivacy} title={"Cookie Policy"} />
        </div>
      </div>
    </div>
  );
}

export default CookiesBanner;
