import React, { useState } from "react";
import { PRODUCTS } from "../../constants/constants";

function Demo() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [product, setProduct] = useState("");
  const [buttonText] = useState("Send Request");

  function submitRequest(e) {
    e.preventDefault();
    window.open(
      "mailto:hello@thefifthlab.com?subject=Request for Product Demo&body=Hello Fifthlab.%0D%0A %0D%0A My name is " +
        name +
        ". I am interested in " +
        product +
        " and I would like to request a demo of your product. My phone number is " +
        phone +
        " and my email is" +
        email +
        " %0D%0A %0D%0A Kind regards, %0D%0A" +
        name,
      "_blank"
    );
  }
  return (
    <div className="relative z-40 ">
      <form>
        <div className="mb-3">
          <label
            htmlFor="Name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Name
          </label>
          <input
            type="name"
            id="name"
            onChange={(e) => setName(e.target.value)}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
            required
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
            required
          />
        </div>

        <div className="mb-3">
          <label
            htmlFor="company"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            What product are you interested in?
          </label>
          <select
            id="countries"
            onChange={(e) => setProduct(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option defaultValue={"Choose Product"}>Choose Product:</option>
            {PRODUCTS.map((product, i) => (
              <option value={product.slug} key={i}>
                {product.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label
            htmlFor="company"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Phone Number
          </label>
          <input
            inputMode="numeric"
            type="number"
            id="number"
            onChange={(e) => setPhone(e.target.value)}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
            required
          />
        </div>

        <button
          type="submit"
          onClick={(e) => submitRequest(e)}
          className="w-full text-center text-white requestDemoButton"
        >
          {buttonText}
        </button>
      </form>
    </div>
  );
}

export default Demo;
