import React from "react";
import SkeletonLoader from "../Skeleton";

function ArticleBody() {
  const skeletonLoaders = Array.from({ length: 15 });
  return (
    <div className="article-body">
      <div>
        {skeletonLoaders.map((_,i) => (
          <SkeletonLoader
            key={i}
            height={"10px"}
            width={"700px"}
            border={"5px"}
          />
        ))}
      </div>

      <div className="my-5">
        {skeletonLoaders.map((_,i) => (
          <SkeletonLoader
            key={i}
            height={"10px"}
            width={"700px"}
            border={"5px"}
          />
        ))}
      </div>

      <div className="my-5">
        {skeletonLoaders.map((_,i) => (
          <SkeletonLoader
            key={i}
            height={"10px"}
            width={"700px"}
            border={"5px"}
          />
        ))}
      </div>

      <div className="my-5">
        {skeletonLoaders.map((_,i) => (
          <SkeletonLoader
            key={i}
            height={"10px"}
            width={"700px"}
            border={"5px"}
          />
        ))}
      </div>
    </div>
  );
}

export default ArticleBody;
