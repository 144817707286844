import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import NavMenu from "../../components/NavMenu/NavMenu";
import ShowModal from "../../components/RequestDemo/ShowModal";
import fetchArticles from "../../api/api";
import Blog from "../../components/Updates/Blog";
import { useNavigate } from "react-router";
import "./Article.css";

function BlogPage() {
  const [articles, setArticles] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const response = await fetchArticles();
        setArticles(response);
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, [setArticles]);

  const showArticle = (article) => {
    sessionStorage.setItem("article", JSON.stringify(article));
    window.scrollTo(0, 0);
    const id = article.guid.split("/")[4];
    history("/blog/" + id);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog | Fifthlab </title>
        <meta
          name="description"
          content={"Insights and Articles by Fifthlab"}
        />
        <link rel="canonical" href="https://thefifthlab.com" />
      </Helmet>

      <NavMenu className={"bg-white"} url={"insights"} />
      <div className="whiteContainer">
        <ShowModal />
      </div>
      <div className="blog-grid aboutBody">
        {articles &&
          articles.map((article, i) => (
            <Blog
              title={article.title}
              image={"https://cdn-images-1.medium.com/max/612/1*T8ZY8odPg7fU-gte8O6t-A.png"}
              categories={article.categories}
              time={article.pubDate.split(" ")[0]}
              author={article.author}
              key={i}
              url={article.link}
              onClick={() => showArticle(article)}
            />
          ))}
      </div>
    </div>
  );
}

export default BlogPage;
